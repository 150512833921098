import {Controller} from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [ "input", "frame", "loader", "button"];

  load(event){
    this.frameTarget.addEventListener('turbo:frame-load', this.enableButton.bind(this));
    this.disableButton();
  }

  disconnect() {
    this.frameTarget.removeEventListener('turbo:frame-load', this.enableButton.bind(this));
  }

  disableButton() {
    if (this.hasButtonTarget) {
      this.buttonTarget.disabled = true;
    }
  }

  enableButton() {
    if (this.hasButtonTarget) {
      this.buttonTarget.disabled = false;
      this.frameTarget.removeEventListener('turbo:frame-load', this.enableButton.bind(this));
    }
  }
}
