import { Controller } from "@hotwired/stimulus";
import consumer from "../channels/consumer";

export default class extends Controller {
    static targets = ['pipelines', 'comparePipelinesButton', 'checkbox'];
    static values = {
        id: String
    }

    connect() {
        const self = this;

        this.channel = consumer.subscriptions.create({
            channel: "RunnableChannel",
            runnable: this.idValue,
        }, {
            received(data) {
                self.received(data);
            }
        });
    }

    disconnect() {
        this.channel.unsubscribe();
    }

    received(data) {
        console.log("data received %o", data);
        if(data.reload) {
            fetch(window.location.href,
                { headers: { "X-Requested-With": "XMLHttpRequest" }})
                .then(response => response.text())
                .then(html => this.pipelinesTarget.innerHTML = html);
        }
    }

    countSelectedPipelines() {
        var numberOfCheckedBoxes = this.checkboxTargets.map(checkbox => checkbox.checked).filter(checked => checked).length
        if (numberOfCheckedBoxes == 2) {
            this.comparePipelinesButtonTarget.disabled = false
            this.comparePipelinesButtonTarget.title = ""
        }
        else {
            this.comparePipelinesButtonTarget.disabled = true
            this.comparePipelinesButtonTarget.title = "You need to select exactly two checkboxes"
        }
    }
}
