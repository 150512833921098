import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ["icon", "input"]
  connect() {
    this.hidden = true;
  }

  toogle(event) {
    event.preventDefault();

    this.hidden = !this.hidden;
    this.iconTarget.classList.toggle("fa-eye");
    this.iconTarget.classList.toggle("fa-eye-slash");
    this.inputTarget.setAttribute("type", this.hidden ? "password" : "text");
  }
}
