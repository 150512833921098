import { Controller } from "@hotwired/stimulus";
import difflib from "../lib/difflib";
import diffview from "../lib/diffview";

export default class extends Controller {
  static targets = [ "body", "firstHtmlComparison", "secondHtmlComparison" ]
  static values = {
    first: String,
    second: String,
    firstTitle: String,
    secondTitle: String,
    type: String
  }

  connect() {
    Promise.all([
      fetch(this.firstValue).then(res => res.text()),
      fetch(this.secondValue).then(res => res.text())
    ]).then(([first, second]) => {
      switch (this.typeValue) {
        case "html":
          this.firstHtmlComparisonTarget.srcdoc = first
          this.secondHtmlComparisonTarget.srcdoc = second
          break
        default:
          const comparedText = difflib.stringAsLines(first)
          const compareToText = difflib.stringAsLines(second)
          const sm = new difflib.SequenceMatcher(comparedText, compareToText)

          while(this.bodyTarget.firstChild) {
            this.bodyTarget.removeChild(this.bodyTarget.firstChild);
          }

          this.bodyTarget.appendChild(diffview.buildView({
            baseTextLines: comparedText,
            newTextLines: compareToText,
            opcodes: sm.get_opcodes(),
            baseTextName: this.firstTitleValue,
            newTextName: this.secondTitleValue,
            contextSize: 3,
            viewType: 0
          }));
      }
    });
  }
}
