import {Controller} from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [ "input", "frame", "loader" ];
  static values = { url: String }

  connect() {
    this.urlParams = [...this.urlValue.matchAll(/:[^\/]+/g)].map(p => p[0].slice(1));
  }

  load(event){
    if(this.inputTarget.value !== ""){
      this.frameTarget.replaceChildren(this.loaderTarget.content.cloneNode(true));
      this.frameTarget.src = this.frameSrc
    }
    else{
      this.frameTarget.innerHtml = ""
    }
  }

  get frameSrc() {
    const params = this.params;

    const url = this.urlParams.reduce((urlValue, param_name) => {
      return urlValue.replaceAll(`:${param_name}`, params[param_name]);
    }, this.urlValue);

    const queryParams = Object.keys(params).reduce((qp, param_name) => {
      if (!this.urlParams.includes(param_name)) {
        qp.append(param_name, params[param_name])
      }
      return qp;
    }, new URLSearchParams());

    return `${url}?${queryParams}`;
  }

  get params() {
    return this.inputTargets.reduce((map, t) => {
      const name = /\[(?<name>.+)\]/.exec(t.name).groups["name"];
      const value = t.value;

      map[name] = value;
      return map;
    }, {});
  }
}
