import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["body"]
  static values = { url: String }

  perform(e) {
    e.preventDefault();

    fetch(this.urlValue)
      .then(response => response.text())
      .then(html => this.bodyTarget.innerHTML = html);
  }
}
