import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ["select", "names", "values"]

  reload(e) {
    if (e != null) { e.preventDefault(); }

    const names = this.namesTargets.map(e => e.value);
    const values = this.valuesTargets.map(e => e.value);
    let selected = undefined;
    if (this.selectTarget.selectedIndex != undefined) {
      selected = this.selectTarget.options[this.selectTarget.selectedIndex];
    }

    if (this.selectTarget.options != undefined) {
      this.selectTarget.options.length = 0
    }

    this.addOption("", "", false);
    for (let i = 0; i < names.length; i++) {
      const isSelected = selected != undefined &&
        values[i] == selected.value || names[i] == selected.text;

      this.addOption(names[i], values[i], isSelected);
    }
  }

  // This is small hack to invoke action after element is removed.
  // It is connected with the fact, that if we have 2 stimulus actions
  // and the first one removes source element than the second action is not
  // invoked.
  delayedReload(e) {
    const self = this;
    setTimeout(function() { self.reload(null) }, 1);
  }

  addOption(text, value, selected) {
    const opt = document.createElement("option");
    opt.text = text;
    opt.value = value;
    opt.selected = selected

    this.selectTarget.add(opt);
  }
}
