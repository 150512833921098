import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["changeSource", "body"]
  static values = { urlPattern: String }

  change(e) {
    fetch(this.urlPatternValue.replaceAll("${value}", this.value))
      .then(response => response.text())
      .then(html => this.bodyTarget.innerHTML = html);
  }

  get value() {
    if(this.changeSourceTarget.type == "checkbox") {
      return this.changeSourceTarget.checked;
    } else {
      return this.changeSourceTarget.value;
    }
  }
}
