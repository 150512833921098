import "trix";
import "@rails/actiontext";
import "@hotwired/turbo-rails"

import "./controllers";

import "./application/time";
import "./application/choices";

import "jquery";
import Popper from "popper.js"
import "bootstrap";

import '@fortawesome/fontawesome-free/js/fontawesome';
import '@fortawesome/fontawesome-free/js/solid';
import '@fortawesome/fontawesome-free/js/regular';
