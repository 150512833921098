import Choices from 'choices.js'

document.addEventListener("turbo:load", function(event) {
  document.querySelectorAll('[data-editable-choice]').forEach(function(element) {
    let innerClasses = 'choices__inner';
    if (element.classList.contains('is-invalid')) {
      innerClasses = innerClasses + ' choices__is-invalid';
    } else if (element.classList.contains('is-valid')) {
      innerClasses = innerClasses + ' choices__is-valid';
    }

    new Choices(element, {
      delimiter: ',',
      editItems: true,
      removeItemButton: true,
      duplicateItems: false,
      addItem: true,
      classNames: {
        containerInner: innerClasses
      }
    });
  });

  document.querySelectorAll('[data-choice]').forEach(function(element) {
    let innerClasses = 'choices__inner';
    if (element.classList.contains('is-invalid')) {
      innerClasses = innerClasses + ' choices__is-invalid';
    } else if (element.classList.contains('is-valid')) {
      innerClasses = innerClasses + ' choices__is-valid';
    }

    new Choices(element, {
      placeholder: true,
      removeItemButton: true,
      classNames: {
        containerInner: innerClasses
      }
    });
  });
});
