import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ["body"]

  initialize() {
    this.indexCounter = 0;
  }

  add(e) {
    e.preventDefault();

    if ("url" in e.target.dataset) {
      this.loadTemplateFromUrl(e.target.dataset.url);
    } else {
      this.loadTemplate(e.target.dataset.templateId);
    }
  }

  remove(e) {
    e.preventDefault();

    const item = e.target.closest("[data-dynamic-form-target='entry']")
    const removeInput = item.querySelector("input[name*='_destroy']")

    if (removeInput != undefined) {
      removeInput.value = 1;
      item.style.display = 'none';
    } else {
      item.remove();
    }
  }

  // private

  loadTemplateFromUrl(url) {
    fetch(url)
      .then(response => response.text())
      .then(html => this.appendChild(html))
  }

  loadTemplate(templateId) {
    const template = document.getElementById(templateId);
    const content = template.innerHTML.replace(/js_template_id/g, this.generatedId);
    this.appendChild(content);
  }

  appendChild(content) {
    this.bodyTarget.insertAdjacentHTML('beforeend', content);
  }

  get generatedId() {
    return new Date().getTime() + this.indexCounter++;
  }
}
