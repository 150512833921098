import { Controller } from "@hotwired/stimulus";
import Choices from 'choices.js'

export default class extends Controller {
  static targets = ["list", "step", "stepsContainer", "templates", "position"]

  connect() {
    new Choices(this.listTarget, {
      removeItemButton: true,
      duplicateItems: false,
    });

    const self = this;
    this.listTarget.addEventListener("addItem", event => self.addStep(event.detail.value));
    this.listTarget.addEventListener("removeItem", event => self.removeStep(event.detail.value));
  }

  addStep(stepId) {
    const step = this.findStep(stepId);

    if(step) {
      const destroyInput = this.stepDestroyInput(step);

      destroyInput.value = "false";
      this.stepsContainerTarget.appendChild(step);
      step.classList.remove("d-none");
    } else {
      const template = this.stepTemplate(stepId);
      const newStep = document.createRange().createContextualFragment(template).firstChild;

      this.stepsContainerTarget.appendChild(newStep);
    }

    this.updateStepsOrder();
  }

  removeStep(stepId) {
    const step = this.findStep(stepId);

    if(step) {
      const destroyInput = this.stepDestroyInput(step);

      if(destroyInput) {
        destroyInput.value = "true";
        step.classList.add("d-none");
      } else {
        step.remove();
      }
    }

    this.updateStepsOrder();
  }

  up(event) {
    event.preventDefault();

    const current = event.target.closest("[data-flow-steps-target='step']");
    const previous = current.previousElementSibling;

    if (previous != undefined) {
      current.parentNode.insertBefore(current, previous);
      this.updateStepsOrder();
    }
  }

  down(event) {
    event.preventDefault();

    const current = event.target.closest("[data-flow-steps-target='step']");
    const next = current.nextElementSibling;

    if (next != undefined) {
      current.parentNode.insertBefore(next, current);
      this.updateStepsOrder();
    }
  }

  updateStepsOrder() {
    let counter = 0;
    this.stepTargets
      .filter(step => !step.classList.contains("d-none"))
      .forEach((step, index) => {
        this.counter(step).innerHTML = counter + 1;
        this.stepPositionInput(step).value = counter;
        counter = counter + 1;
      });
  }

  findStep(stepId) {
    return this.stepTargets.find(step => this.stepId(step) == stepId);
  }

  stepId(step) {
    return step.querySelector("input[name$='step_id]']").value;
  }

  stepPositionInput(step) {
    return step.querySelector("input[name$='position]']");
  }

  counter(step) {
    return step.querySelector("span.position");
  }

  stepDestroyInput(step) {
    return step.querySelector("input[name$='_destroy]']");
  }

  stepTemplate(stepId) {
    return this.templatesTarget.getAttribute(`data-${stepId}`);
  }
}
