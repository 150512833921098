function format(num) {
  let r = '' + num;
  while (r.length < 2) {
    r = '0' + r;
  }

  return r;
}

function tick() {
  const elements = document.querySelectorAll("td[data-computation-start]");

  elements.forEach(el => {
    const start = Date.parse(el.dataset.computationStart);
    const diff = Math.floor((new Date() - start)/1000);

    const hours = Math.floor(diff / 3600);
    const minutes = Math.floor(diff / 60) % 60;
    const seconds = diff % 60;

    el.innerText = `${format(hours)}h ${format(minutes)}m ${format(seconds)}s`;
  });

  if (elements.length > 0) {
    setTimeout(tick, 1000);
  }
}

document.addEventListener("turbo:load", function(event) { tick(); });
